<script setup>
import HrbrSidebarPaneFilter from './HrbrSidebarPaneFilter.vue';
import HrbrSidebarPaneClose from './HrbrSidebarPaneClose.vue';
</script>

<template>
  <div class="hrbr-sidebar-pane-header">
    <HrbrSidebarPaneFilter />
    <HrbrSidebarPaneClose />
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  height: 64px;
  border-bottom: 1px solid #e4e9ec;
}
</style>
