<script setup>
import { useWorkflowsStore } from '@/stores/workflows-store';
import { DialogProgrammatic as Dialog } from 'buefy';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

const agParams = ref(null);
const workflowsStore = useWorkflowsStore();
const { workflows, workflowGroups, activeWorkflowGroup } = storeToRefs(workflowsStore);
const { addWorkflowFromPrompt, renameWorkflowGroup, deleteWorkflowGroup } = workflowsStore;

const init = (params) => {
  agParams.value = params;
};

const overflow = ref(null);
const handleOverflowToggle = () => {
  overflow.value.toggle();
};

const handleNewWorkflow = (params) => {
  if (!params.node?.key) return;
  activeWorkflowGroup.value = params.node.key;
  overflow.value.isActive && handleOverflowToggle();

  const callback = () => {
    agParams.value.expandNode(params.node);
  }
  addWorkflowFromPrompt(callback, isOrgWide.value);
};

const handleDelete = () => {
  Dialog.confirm({
    title: 'Delete workflow group',
    message: 'Are you sure you want to remove this group and all of its workflows?',
    confirmText: 'Delete',
    cancelText: 'Cancel',
    type: 'is-danger',
    onConfirm: () => {
      deleteWorkflowGroup(agParams.value.value);
    },
  });
};

const handleRename = () => {
  Dialog.prompt({
    title: 'Rename workflow group',
    message: `Enter a new name for ${getName.value}`,
    confirmText: 'Update',
    cancelText: 'Cancel',
    onConfirm: (value) => {
      renameWorkflowGroup(agParams.value.value, value);
    },
  });
};

const getName = computed(() => {
  const group = workflowGroups.value.find((group) => group.id === agParams.value.value);
  if (!group) return 'All workflows';
  return group?.name;
});

const isOrgWide = computed(() => {
  const group = workflowGroups.value.find((group) => group.id === agParams.value.value);
  return group?.is_org_wide;
});

const hasReachedOrgWideLimit = computed(() => {
  const orgWideWorkflows = workflows.value.filter((workflow) => workflow.isOrgWide);
  return orgWideWorkflows.length >= 2;
});

const showMenu = ref(false);

</script>

<template>
  <div
    class="workflow-group"
    :ref="init(params)"
    @mouseenter="showMenu = true"
    @mouseleave="showMenu = false">
    <div class="workflow-name">
      <i class="workflow-icon fad fa-code-branch" v-if="params.node.level === 0"></i>
      <i class="workflow-icon fal fa-code-branch" v-if="params.node.level === 1 && !isOrgWide"></i>
      <i class="workflow-icon fal fa-globe" v-if="params.node.level === 1 && isOrgWide"></i>
      <span class="workflow-name">
        {{ getName }}
      </span>
    </div>

    <div v-show="params.node.level === 1 && showMenu" class="buttons-area" v-if="!params.value?.startsWith('temp')">
      <b-tooltip v-if="hasReachedOrgWideLimit && isOrgWide" label="You can have only 2 org-wide workflows"
        position="is-right"
        type="is-black"
        append-to-body>
        <button disabled class="add-workflow" @click.stop.prevent="handleNewWorkflow(params)" :style="{ cursor: 'not-allowed'}">
          <i class="fa-solid fa-plus" title="Create new workflow"></i>
        </button>
      </b-tooltip>
      <button v-else class="add-workflow" @click.stop.prevent="handleNewWorkflow(params)">
          <i class="fa-solid fa-plus" title="Create new workflow"></i>
      </button>


      <b-dropdown :disabled="isOrgWide" aria-role="list" ref="overflow" position="is-bottom-left" append-to-body>
        <template #trigger="{ active }">
          <button class="add-workflow menu-dropdown" @click.stop.prevent="handleOverflowToggle">
            <i class="fal fa-ellipsis-v" title="More options"></i>
          </button>
        </template>

        <b-dropdown-item aria-role="listitem" class="dropdown-item" @click="handleRename(params)">
          <i class="fal fa-pencil"></i>Rename
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem" class="dropdown-item" @click="handleDelete(params)">
          <i class="fal fa-trash"></i>Delete
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div v-else class="creating-loader">
      <i class="fal fa-spinner-third fa-spin"></i>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.creating-loader {
  color: #999;
}
.workflow-name {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menu-dropdown {
  &__dropdown {
    :deep(.dropdown-trigger) {
        font-size: 13px;
        cursor: pointer;
        padding: 0 4px;
        transition: color 0.15s;

        &:hover {
            color: var(--hrbr-primary-color-active);
        }
    }

    :deep(.background) {
        display: none !important;
    }

    &.is-active :deep(.dropdown-trigger) {
        visibility: visible !important;
    }
  }
}
.workflow-name {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fa-trash {
  color: red;
}
.dropdown-item i {
  margin-right: 10px;
}
.buttons-area {
  display: flex;
  padding-left: 3px;
}
.add-workflow {
  font-family: inherit;
  font-size: 14px;
  color: #8b9198;
  font-weight: 600 !important;
  padding-top: 15px;
  cursor: pointer;
  transition: color 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  padding: 0;
  cursor: pointer;
  background: none;
  border: none !important;
  outline: none;
  appearance: none;
}
.add-workflow:hover {
  color: var(--hrbr-primary-color-active) !important;
}
.workflow-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 9px;
}
.add-button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 3px;
  font-weight: 500;
}
.workflow-group:hover {
}
.workflow-icon {
  margin-right: 10px;
}
</style>
