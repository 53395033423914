<script setup>
import Vue, { computed, reactive, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { ModalProgrammatic as Modal, ToastProgrammatic as Toast } from 'buefy';
import HrbrButton from '@components/ui/HrbrButton.vue';
import { useHarbourStore } from '@/stores/harbour-store.js';
import HrbrEmailSendModal from '@components/Modals/HrbrEmailSend.vue';
import magicAnnotationsService from '@/services/magic-annotations-service.js';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import filesystemApiService from "@/services/filesystem-api-service.js";

const magicAnnotationsStore = useMagicAnnotationsStore();
const harbourStore = useHarbourStore();

const {
  finalOnlySignerModalOpened,
  uploadedDocData,
  finalAssetId,
  isLoading,
  recordId
} = storeToRefs(magicAnnotationsStore);

const { myFolders } = storeToRefs(harbourStore);
const recipients = ref([]);
const availableFolders = ref([]);
const selectedFolder = ref(null);
const emailSent = ref(false);
const isEmailRequestLoading = ref(false);

const doneButton = reactive({
  show: true,
  text: 'Done',
  action: () => {
    moveAsset();
    onModalClose();
  },
});

const sendEmailButton = reactive({
  show: true,
  text: 'Send email',
  iconLeft: 'far fa-paper-plane',
  loading: computed(() => isEmailRequestLoading.value),
  action: () => {
    sendEmail();
  },
});

const foldersButton = reactive({
  iconRight: 'fas fa-caret-down',
  iconLeft: 'far fa-folder',
  activeSelection: selectedFolder.id,
  options: availableFolders,
});

const getFolderForDropdown = () => {
  availableFolders.value = myFolders.value.filter(folder => folder.id !== '#shared').map(folder => ({
    id: folder.id,
    value: folder.name,
    stickyTitle: true,
    action: () => selectedFolder.value = folder,
  }));
  selectedFolder.value = availableFolders.value.find(folder => folder.id === '#home');
};

watch(
  () => myFolders.value,
  () => {
    getFolderForDropdown();
  },
  { deep: true, immediate: true }
);

const openExtendedModal = () => {
  // ToDo review props
  Modal.open({
    component: HrbrEmailSendModal,
    hasModalCard: true,
    props: {
      requestemailtype: 'REVIEWANDSIGNEMAIL',
      requesttitle: uploadedDocData.value.fileDocumentName,
      requestmessage: '',
      toemailsarray: recipients,
      ccemailsarray: [],
      agreelinkdisplayid: '',
      agreementtitle: uploadedDocData.value.fileDocumentName,
      emailInputData: {},
      autoClose: true,
    },
  });
}

const sendEmail = async () => {
  try {
    isEmailRequestLoading.value = true;
    await magicAnnotationsService.sendEmail(recordId.value, recipients.value)

    emailSent.value = true;
    isEmailRequestLoading.value = false;
  } catch (e) {
    console.error(e);
    isEmailRequestLoading.value = false;
    Toast.open({
      message: 'Not able to send email at time. Try again in a bit or contact suppport@harbourshare.com',
      type: 'is-danger',
      position: 'is-top',
      duration: 3500,
    });
  }
};

const moveAsset = async () => {
  try {
    await Vue.prototype.$harbourData.post('data?filesystem-moveassetstonewdestinationfolder', {
      'requesttype': 'filesystem-moveassetstonewdestinationfolder',
      'assetidstomove': [finalAssetId.value],
      'targetfolderid': selectedFolder.value.id,
      'previousfolderid': '#home',
    });
  } catch (e) {
    console.error(e);
    Toast.open({
      message: 'Error: unable to move items at this time: please try again later or contact support@harbourshare.com',
      type: 'is-danger',
      position: 'is-top',
      duration: 3500,
    });
  }
};

const downloadAsset = async () => {
  const toast = Toast.open({
    duration: 8000000,
    message: `Starting your download now...`,
    position: 'is-bottom',
    type: 'is-black',
  });

  try {
    const payload = {
        assetDisplayIds: [finalAssetId.value],
        rootFolderName: 'My folders'
    }
    const respData = await filesystemApiService.getAssetDownloadZip(payload);
    const downloadUrl = respData.zipdownloadsignedurl;
    window.location.href = downloadUrl;
  } catch (err) {
    Toast.open({
        duration: 2500,
        message: `Unable to create download at this time. Please refresh and try again later or contact support@harbourshare.com`,
        position: 'is-top',
        type: 'is-warning',
    });
    console.error(err);
  } finally {
    toast.close();
  }
};

const onModalClose = () => {
  finalOnlySignerModalOpened.value = false;
  emailSent.value = false;
  magicAnnotationsStore.clearAgreementData();
};
</script>

<template>
  <b-modal
    :active="finalOnlySignerModalOpened"
    trap-focus
    custom-class="only-signer-modal"
    data-testid="only-signer-final-modal"
    aria-role="dialog"
    aria-label="I'm only signer final modal"
    close-button-aria-label="Close"
    aria-modal
    :on-cancel="onModalClose"
  >
    <template #default="props">
      <div
        class="modal-card"
      >
        <b-loading
          :active="isLoading"
          :can-cancel="false"
          animation=""
        />
        <header class="modal-card-head">
          <h3 class="modal-card-title">
            <i class="fa-light fa-paper-plane"></i>
            Final step: send a copy
          </h3>
          <p class="modal-card-subtitle">
            Your document is now signed and saved
          </p>
        </header>
        <section class="modal-card-body">
          <div class="content-block">
            <div class="content-block__title">
              <span class="content-block__title-main">Add recipients</span>
              <!--  No backend for this feature yet  -->
              <span
                v-if="false"
                class="content-block__extended-modal-link"
                @click="openExtendedModal"
              >
                Customize inputs
              </span>
            </div>
            <div class="content-block__field autocomplete">
              <EmailInput
                placeholder="Type email here..."
                icon-pack="far"
                icon="envelope"
                ellipsis
                :maxlength="500"
                :has-counter="false"
                maxtags="50"
                :readonly="false"
                :on-paste-separators="[',', ' ']"
                v-model="recipients"
                :email-store="recipients"
                :results-limit="30"
                :recents-tags-limit="5"
                :show-self-in-recents="true"
                attached
              />

              <HrbrButton
                v-if="!emailSent"
                class-name="content-block__btn send-email-btn"
                :button="sendEmailButton"
              />
              <div
                v-else
                class="content-block__email-sent"
              >
                <i class="fa-regular fa-check"></i>
                Sent
              </div>
            </div>
          </div>
          <div class="content-block content-block--with-cols">
            <div class="content-block__title">
              <p class="content-block__title-main">Move to folder</p>
              <span class="content-block__title-sub">Choose the folder to store the signed agreement</span>
            </div>
            <div class="folders-dropdown">
              <HrbrButton
                v-if="availableFolders.length"
                class-name="content-block__btn dropdown-btn"
                :button="foldersButton"
                :initial-selection="selectedFolder.id"
              />
            </div>
          </div>

          <div class="content-block content-block--with-cols">
            <div class="content-block__title">
              <p class="content-block__title-main">Download</p>
              <span class="content-block__title-sub">Downloads copy of signed agreement</span>
            </div>
            <HrbrButton
              class-name="content-block__btn"
              :button="{
                text: 'Download',
                styleType: 'secondary',
                iconLeft: 'fa-regular fa-file-arrow-down'
              }"
              @click="downloadAsset"
            />
          </div>
        </section>
        <footer class="modal-card-foot">
          <HrbrButton class-name="content-block__btn confirm-btn" :button="doneButton" />
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/elements/email-autocomplete-new.css";
.only-signer-modal {
  &:deep(.modal) {
    &-content {
      width: auto;
    }
    &-card-head, &-card-foot {
      padding: 16px 32px;
      background-color: #fff;
    }
    &-card-body {
      padding: 0 32px;
      min-height: 360px;
    }
    &-card-head {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 32px;
      border-bottom: none;
    }
    &-card-title {
      margin-bottom: 8px;
      font-size: 24px;
      color: #333;
      i {
        font-size: 22px;
      }
    }
    &-card-subtitle {
      color: #00853D;
    }
  }
  .content-block {
    padding: 16px 0;
    border-top: 1px solid #DBDBDB;
    &--with-cols {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      .content-block__title {
        display: block;
        margin-bottom: 0;
      }
    }
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      color: #333;
    }
    &__title-main {
      font-weight: 600;
    }
    &__title-sub {
      font-size: 12px;
    }
    &__field {
      display: flex;
      align-self: center;
      justify-content: space-between;
      gap: 16px;
    }
    &__extended-modal-link {
      font-size: 12px;
      color: #1355FF;
      cursor: pointer;
      pointer-events: none;
      opacity: .5;
      &:hover {
        color: #0133B7;
      }
    }
    &__email-sent {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 22px;
      color: #00853D;
      font-weight: 600;
    }
    &__btn {
      :deep(.section-btn) {
        padding: 5px 16px;
        border-radius: 8px;
        border-color: #DBDBDB;
        font-size: 14px;
        font-weight: 600;
        color: #333;
        &:hover {
          background-color: #DBDBDB;
        }
      }
    }
    .folders-dropdown {
      margin-left: auto;
    }
    .vue-email-input {
      width: calc(100% - 176px);
      :deep(.taginput) {
        .taginput-container {
          border-color: #DBDBDB;
          border-radius: 8px;
          box-shadow: none;
          &.is-focused {
            border-color: #1355FF;
            box-shadow: none;
            color: #333;
            .icon {
              color: #333;
            }
          }
        }
        .dropdown-content {
          border-radius: 8px;
        }
      }
    }
  }
  .send-email-btn, .dropdown-btn {
    min-width: 160px;
    :deep(.section-btn) {
      width: 100%;
    }
  }
  .download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 7px 22px;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    &:hover {
      background-color: #DBDBDB;
    }
  }
}
</style>
